import device from "current-device";
import { debounce } from "debounce";
import { videoStartEvent, videoStopEvent } from "./events";

const ALL_DEVICE_TYPES = ["desktop", "mobile-portrait", "mobile-landscape"];
const getActiveDeviceType = () => {
    if (device.desktop()) return ALL_DEVICE_TYPES[0];
    if (!device.desktop() && device.portrait()) return ALL_DEVICE_TYPES[1];
    if (!device.desktop() && device.landscape()) return ALL_DEVICE_TYPES[2];
};

window.requestAnimationFrame(() => {
    const videoElements = document.querySelectorAll<HTMLVideoElement>(
        "video[device-target]"
    );

    const activateDeviceOnStartup = () => {
        let activated = false;

        const handleMouseMove = () => {
            if (activated) return;
            activated = true;
            document.body.classList.add("cursor-wait")
            setTimeout(() => {
                updateActiveDeviceType();
                window.removeEventListener("mousemove", handleMouseMove);
                window.removeEventListener("touchmove", handleMouseMove);
                window.removeEventListener("touchstart", handleMouseMove);
            }, 3 * 1000);
        };

        if (device.mobile()) {
            setTimeout(() => {
                if (activated) return;
                updateActiveDeviceType();
            }, 1000 * 10);
        }

        window.addEventListener("mousemove", handleMouseMove);
        window.addEventListener("touchmove", handleMouseMove);
        window.addEventListener("touchstart", handleMouseMove);
    };

    const updateHeight = debounce(
        () => {
            window.scrollTo(0, -1);
            const doc = document.documentElement;
            const heightProperty = device.ios() ? `${window.innerHeight}px` : "100vh";
            doc.style.setProperty("--app-height", heightProperty);
            // mad tricks here: https://stackoverflow.com/questions/62717621/white-space-at-page-bottom-after-device-rotation-in-ios-safari
            console.log("updated height", window.innerHeight);
        },
        500,
        false
    );

    const play = async (videoElement, mutedState) => {
        videoElement.muted = mutedState;
        if (videoElement.paused) {
            try {
                await videoElement.play();
            } catch {
                // ugly
                console.log("error on play");
                // videoElement.
                videoElement.muted = true;
                await videoElement.play();
                // videoElement.muted = mutedState;
            } finally {
                videoElement.dispatchEvent(videoStartEvent);
            }
        }
    };

    const pause = (videoElement) => {
        videoElement.pause();
        videoElement.dispatchEvent(videoStopEvent);
    };

    const deactiveNotActiveVideoTargets = () =>
        setTimeout(() => {
            const mutedState = getActiveVideoMutedState();
            videoElements.forEach(async (videoElement) => {
                const deviceTarget = videoElement.getAttribute("device-target");
                // const src = sourceElement.getAttribute("src");
                // const dataSrc = sourceElement.getAttribute("data-src");
                if (deviceTarget === getActiveDeviceType()) {
                    // sourceElement.setAttribute("src", dataSrc || src);
                    // video.load();
                    console.log("start", deviceTarget);
                    play(videoElement, mutedState);
                } else {
                    console.log("pause", deviceTarget);
                    pause(videoElement);

                    // sourceElement.setAttribute("data-src", src || dataSrc);
                    // sourceElement.removeAttribute("src");
                    // video.load();
                }
            });
        }, 500);

    const getActiveVideoMutedState = () => {
        const activeVideo = Array.from(videoElements).find(
            (source) => !(source.parentElement as HTMLVideoElement).muted
        );
        if (!activeVideo) return true;
        console.log("muted state", activeVideo?.muted);
        return activeVideo.muted;
    };

    const updateActiveDeviceType = debounce(
        () => {
            const deviceTypeDidChange = !document.body.classList.contains(
                getActiveDeviceType()
            );
            console.log("new device type", getActiveDeviceType());
            if (!deviceTypeDidChange) return;
            console.log("device type change");

            const willFadeOut =
                document.body.classList.contains("active") &&
                deviceTypeDidChange;
            if (willFadeOut) document.body.classList.remove("active");

            setTimeout(
                () => {
                    ALL_DEVICE_TYPES.forEach((className) =>
                        document.body.classList.remove(className)
                    );
                    document.body.classList.add(
                        "active",
                        getActiveDeviceType()
                    );
                    deactiveNotActiveVideoTargets();
                },
                willFadeOut ? 1500 : 0
            );
        },
        50,
        false
    );

    const playVideoOnStartup = () => {
        videoElements.forEach((video) => {
            play(video, true);
        });
    };

    device.onChangeOrientation(() => {
        updateActiveDeviceType();
        updateHeight();
    });

    window.addEventListener("resize", updateHeight);
    // document.addEventListener("scroll", updateHeight);
    updateHeight();
    activateDeviceOnStartup();
    playVideoOnStartup();
});
